import React, { useEffect } from 'react'
import '../../Styles/Services.css'

const AdvList = [
  {
    name: 'Text',
    subtext: 'The marketing team will develop a Marketing strategy that meets the product market fit criteria. The team will then use that strategy to develop the Text advertisement for your product or service.'
  },
  {
    name: 'Radio (Voice only)',
    subtext: 'The marketing team will develop a Marketing strategy that meets the product market fit criteria. The team will then use that strategy to develop the Radio advertisement for your product or service.'
  },
  {
    name: 'Text and Voice',
    subtext: 'The marketing team will develop a Marketing strategy that meets the product market fit criteria. The team will then use that strategy to develop the Text and Voice advertisement for your product or service.'
  },
  {
    name: 'Video Ad',
    subtext: 'The marketing team will develop a Marketing strategy that meets the product market fit criteria. The team will then use that strategy to develop the Video advertisement for your product or service.'
  },
]

function Advertising() {

  useEffect(() => {
    window.scrollTo(0,0);

  },[])

    document.querySelectorAll('.nav-ext').forEach(x => {
        x.style.display = 'none'
    })

    function showAdDescr(e){
      let targ = e.currentTarget
      let advDescr = targ.parentNode.id
      targ.classList.toggle('show')
      if(targ.className === 'adv-descr show'){
        targ.innerHTML = `<svg style={{pointerEvents:'none'}} stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 1024 1024" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M884 256h-75c-5.1 0-9.9 2.5-12.9 6.6L512 654.2 227.9 262.6c-3-4.1-7.8-6.6-12.9-6.6h-75c-6.5 0-10.3 7.4-6.5 12.7l352.6 486.1c12.8 17.6 39 17.6 51.7 0l352.6-486.1c3.9-5.3.1-12.7-6.4-12.7z"></path></svg>`
        document.getElementById('showAdv'+advDescr).style.display='flex'
      }else{
        targ.innerHTML = `<svg style={{pointerEvent:'none'}} stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 1024 1024" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M765.7 486.8L314.9 134.7A7.97 7.97 0 0 0 302 141v77.3c0 4.9 2.3 9.6 6.1 12.6l360 281.1-360 281.1c-3.9 3-6.1 7.7-6.1 12.6V883c0 6.7 7.7 10.4 12.9 6.3l450.8-352.1a31.96 31.96 0 0 0 0-50.4z"></path></svg>`
        document.getElementById('showAdv'+advDescr).style.display='none'
      }
    }
    
  return (
    <div className='advertising'>
    <div className="advertising-content">
      <h1 className="advertising-title">Advertising</h1>
      <div className='advertising-subtitle'>
        A simple meaningful advertising strategy catering to the consumers needs and their problems <br/>
      <span>This is available in the following formats:</span>
      </div>
      <div className="advertising-descr">
        {AdvList.map((i,k) => {
          return(
          <div key={k} className="advertising-item-container">
            <div id={i.name} className='advertising-item'>
              {i.subtext && 
                <div onClick={showAdDescr} className='adv-descr'>
                  <svg style={{pointerEvent:'none'}} stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 1024 1024" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M765.7 486.8L314.9 134.7A7.97 7.97 0 0 0 302 141v77.3c0 4.9 2.3 9.6 6.1 12.6l360 281.1-360 281.1c-3.9 3-6.1 7.7-6.1 12.6V883c0 6.7 7.7 10.4 12.9 6.3l450.8-352.1a31.96 31.96 0 0 0 0-50.4z"></path></svg>
                </div>
              }
              <p>{i.name}</p>
            </div>
            {i.subtext && 
            <p id={`showAdv${i.name}`} className="advertising-subtext">{i.subtext}</p>
            }
          </div>
          )
        })}
      </div>
    </div>
  </div>
  )
}

export default Advertising
