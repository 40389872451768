import React from 'react'
import { useEffect } from 'react'
import '../../Styles/Research.css'

function Simts() {

  useEffect(() => {
    window.scrollTo(0,0)
  },[])


  return (
    <div className='simts'>
      <div className="simts-content">
        <h1 className="simts-title">Siddhi Institute of Leadership and Technology (SILT)</h1>
        <div className="simts-descr">
          <div className="simts-item">Develop research and data driven solutions for complex world problems</div>
          <div className="simts-item">Our goal to perform adequate research and understand the problems of the customers both in the Consumer world and in the Business world</div>
          <div className="simts-item">This research helps us tailor the solution address the specific problem with a permanent solution</div>
        </div>
      </div>
    </div>
  )
}

export default Simts