import React from 'react';
import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Navbar from './Components/Navbar';
import Home from './Components/Home';
import Aboutus from './Components/Aboutus';
import Career from './Components/Career';
import Contact from './Components/Contact';
import Mission from './Components/Mission';
import Investments from './Components/Investments';
import Training from './Components/Training';
import Products from './Components/Products';
import Advertising from './Components/Services/Advertising';
import Consulting from './Components/Services/Consulting';
import Finance from './Components/Services/Finance';
import Simts from './Components/Research/Simts';
import ConsumerSc from './Components/Research/ConsumerSc';
import B2bSc from './Components/Research/B2bSc';
import { useEffect } from 'react';
import bgColor from './Assets/bg-color.jpg'
import bgMono from './Assets/bg-mono.jpg'
import Publications from './Components/Research/Publications';
import { useState } from 'react';
import Footer from './Components/Footer';


function App() {


  useEffect(() => {  
  
    if(sessionStorage.getItem('theme') != null){
        // console.log("YES");
      if(sessionStorage.getItem('theme') === 'home'){
        document.body.style.backgroundImage = `url(${bgColor})`;
      }else{
        document.body.style.backgroundImage = `url(${bgMono})`;
      }
    }
    else {
      // console.log("NO");
      document.body.style.backgroundImage = `url(${bgColor})`;
    }

  },[])


  function clickPage(x){
      
    // console.log("clickedNav",x);
    
    if(x === 'home'){
      document.body.style.backgroundImage = `url(${bgColor})`;
    }
    else{
      if(x === 'publications'){
        sessionStorage.setItem('activeNav', 'research')
      }
      document.body.style.backgroundImage = `url(${bgMono})`;
    }

    sessionStorage.setItem('theme',x)
  }



  let [pubIndex, setPubIndex] = useState(null) 
  function showPub(y){
    // console.log('hi', y);
    setPubIndex(y)
    clickPage('publications')
  }

  function getPub(z){
    showPub(z)
  }
 
  
  return (<>
    <div className="App">
     <Router>
      <Navbar page={clickPage}/>
      <Routes>
        <Route path='/' element={<Home showPub={showPub} />} />
        <Route path='/products' Component={Products}/>
        <Route path='/services/advertising' Component={Advertising}/>
        <Route path='/services/consulting' Component={Consulting}/>
        <Route path='/services/finance' Component={Finance}/>
        <Route path='/research/simts' Component={Simts}/>
        <Route path='/research/consumerscience' Component={ConsumerSc}/>
        <Route path='/research/b2bscience' Component={B2bSc}/>
        <Route path='/research/publications' element={<Publications pub={pubIndex} getPub={getPub} />} />
        <Route path='/training' Component={Training}/>
        <Route path='/investments' Component={Investments}/>
        <Route path='/mission' Component={Mission}/>
        <Route path='/contact' Component={Contact}/>
        <Route path='/career' Component={Career}/>
        <Route path='/aboutus' Component={Aboutus}/>
      </Routes>
      <Footer />
     </Router>
    </div>
    </>
  );
}

export default App;
