import React, { useRef } from 'react'
import { useEffect, useState } from 'react'
// import emailjs from '@emailjs/browser';
import {EmailClient, KnownEmailSendStatus} from '@azure/communication-email'
import '../Styles/Contact.css'

function Contact() {

  useEffect(() => {
    window.scrollTo(0,0)
  },[])

  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    company: '',
    phoneNumber: '',
    email: '',
    likedFeatures: '',
    includeFeatures: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  // const handleSubmit = (e) => {
  //   e.preventDefault();
  //   // You can perform any actions with the form data here, like sending it to a server
  //   console.log('Form submitted:', formData);
  //   // Reset the form after submission
  //   setFormData({
  //     firstName: '',
  //     lastName: '',
  //     company: '',
  //     phoneNumber: '',
  //     email: '',
  //     message: ''
  //   });
  // };

  const handleCancel = () => {
    document.getElementById('myForm').reset()
 
    setFormData({
      firstName: '',
      lastName: '',
      company: '',
      phoneNumber: '',
      email: '',
      likedFeatures: '',
      includeFeatures: ''
    });
  };

  const form = useRef();

  // const sendEmail = (e) => {
    // e.preventDefault();

    // emailjs.sendForm('service_bsrcfad', 'template_4zjdubv', form.current, 'XQrQnjL98pqlFxNce')
    //   .then((result) => {
    //       console.log("SUCCESS", result.text);
    //       alert('Form submitted..')
    //       handleCancel()
    //   }, (error) => {
    //       console.log("ERROR", error.text);
    //   });

  // };


// This code retrieves your connection string from an environment variable.
const connectionString = "endpoint=https://siddhicommunication.unitedstates.communication.azure.com/;accesskey=AdyhynfyIdnuWUCuITyW6oOXpY7j8Xm4igt1pkdNWXUW7to19DvQFsPG+1BcrhjqfCwR77i/zp8TzDMrW8amGQ==";
const client = new EmailClient(connectionString);

async function emailMe(e) {
  e.preventDefault()

  const POLLER_WAIT_TIME = 10
  try {
    const message = {
      senderAddress: "DoNotReply@41f022f7-7ee7-4fd1-9d4f-855aa3cb08e2.azurecomm.net",
      content: {
        subject: "Siddhi Query via Email Services",
        plainText: `You have a new message from ${formData.firstName} ${formData.lastName}\n
        First name: ${formData.firstName},\n
        Last Name: ${formData.lastName},\n
        Company: ${formData.company},\n
        Phone Number: ${formData.phoneNumber},\n
        Email: ${formData.email},\n
        Liked Features: ${formData.likedFeatures},\n
        Include Features: ${formData.includeFeatures}
        `,
      },
      recipients: {
        to: [{ address: "info@siddhiscientific.com" }],
    },
    };

    const poller = await client.beginSend(message);

    if (!poller.getOperationState().isStarted) {
      throw Object.assign(
        new Error("Poller was not started")
     );
    }

    let timeElapsed = 0;
    while(!poller.isDone()) {
      poller.poll();
      // console.log("Email send polling in progress");
      document.querySelector('.messageDisp').style.display = 'flex'
      document.querySelector('.showMessage').innerHTML = 'Sending email..'

      await new Promise(resolve => setTimeout(resolve, POLLER_WAIT_TIME * 1000));
      timeElapsed += 10;

      if(timeElapsed > 18 * POLLER_WAIT_TIME) {
        throw Object.assign(
          new Error("Polling timed out")
       );
      }
    }

    if(poller.getResult().status === KnownEmailSendStatus.Succeeded) {
      // console.log(`Successfully sent the email (operation id: ${poller.getResult().id})`);
      document.querySelector('.showMessage').innerHTML = 'Email sent successfully..'
      handleCancel()

      setTimeout(() => {
        document.querySelector('.messageDisp').style.display = 'none'
      },1500)
    }
    else {
      throw poller.getResult().error;
    }
  } catch (e) {
    throw Object.assign(
      new Error(e)
   );
  }
}


 
  return (
    <div className='contact'>
      <div className="contact-content">
        <h1 className="contact-title">Contact Us</h1>
        {/* <form id='myForm' className='contact-form' ref={form} onSubmit={sendEmail}> */}
        <form id='myForm' className='contact-form' ref={form} onSubmit={emailMe}>
          <div className="cols">
              <div className="col1">
                  <div className="form-control ">
                    <label>First Name: <span>*</span></label>
                    <input required type="text" name="firstName" value={formData.firstName} onChange={handleChange} />
                  </div>

                  <div className="form-control">
                    <label>Last Name:</label>
                    <input type="text" name="lastName" value={formData.lastName} onChange={handleChange} />
                  </div>

                  <div className="form-control">
                    <label>Company:</label>
                    <input type="text" name="company" value={formData.company} onChange={handleChange} />
                  </div>

                  <div className="form-control"> 
                    <label>Phone:</label>
                    <input type="tel" name="phoneNumber" value={formData.phoneNumber} onChange={handleChange} />
                  </div>

                  <div className="form-control"> 
                    <label>Email: <span>*</span></label>
                    <input required type="email" name="email" value={formData.email} onChange={handleChange} />
                  </div>
              </div>
              <div className="col2">
                <div className="form-control"> 
                  <label>Features that you liked in our Apps:</label>
                  <textarea  rows={4} name="likedFeatures" value={formData.likedFeatures} onChange={handleChange} />
                </div>
                <div className="form-control"> 
                  <label>Features that will delight you if included in our Apps: </label>
                  <textarea  rows={4} name="includeFeatures" value={formData.includeFeatures} onChange={handleChange} />
                </div>
              </div>
          </div>

          <div className="form-buttons">
            <button type="submit">Send</button>
            <button type="button" onClick={handleCancel}>Cancel</button>
          </div>
        </form>
      </div>

      <div className="messageDisp">
        <p className='showMessage'></p>
      </div>

    </div>

  )
}

export default Contact