// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footer{
    width: 100%;
    height: 30px;
    position: fixed;
    bottom: 0;
    background-color: white;
    color: black;
    display: flex;
    justify-content: center;
    align-items: center;
    border-top: 1px solid black;
}

.footer p{
    display: flex;
    gap: 5px;
    align-items: center;
    font-size: 14px;
}

.footer p span{
    display: flex;
}`, "",{"version":3,"sources":["webpack://./src/Styles/Footer.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,YAAY;IACZ,eAAe;IACf,SAAS;IACT,uBAAuB;IACvB,YAAY;IACZ,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,2BAA2B;AAC/B;;AAEA;IACI,aAAa;IACb,QAAQ;IACR,mBAAmB;IACnB,eAAe;AACnB;;AAEA;IACI,aAAa;AACjB","sourcesContent":[".footer{\r\n    width: 100%;\r\n    height: 30px;\r\n    position: fixed;\r\n    bottom: 0;\r\n    background-color: white;\r\n    color: black;\r\n    display: flex;\r\n    justify-content: center;\r\n    align-items: center;\r\n    border-top: 1px solid black;\r\n}\r\n\r\n.footer p{\r\n    display: flex;\r\n    gap: 5px;\r\n    align-items: center;\r\n    font-size: 14px;\r\n}\r\n\r\n.footer p span{\r\n    display: flex;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
